import { audioCost, superchargeCost, superchargedAudioCost, thinkingCost } from "@/pages/api/trpc/messages/quillCosts";
import { monthlyQuillCount } from "@/pages/api/webhooks/monthlyQuillCount";
import { useUserProfile } from "@/utils/auth/useUserProfile";
import { trpc } from "@/utils/trpc";
import StarsIcon from "@/../public/icons/stars.svg";
declare global {
  interface Window {
    LemonSqueezy: any;
  }
}
import { Button, Dialog, Text, Flex, Heading, Box, RadioCards, Card, Link as RadixLink, Separator } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { SpellboundIcon } from "../../home/spellboundIcon";
import Link from "next/link";
import { useOverlay } from "./lemonSqueezyOverlay";
import Head from "next/head";
import { Share1Icon, Share2Icon } from "@radix-ui/react-icons";
import { FaExternalLinkAlt } from "react-icons/fa";

// Define subscription options data
function QuillCostDialog({
  trigger
}: {
  trigger: React.ReactNode;
}) {
  return <Dialog.Root data-sentry-element="unknown" data-sentry-component="QuillCostDialog" data-sentry-source-file="subscriptionDialog.tsx">
      <Dialog.Trigger data-sentry-element="unknown" data-sentry-source-file="subscriptionDialog.tsx">{trigger}</Dialog.Trigger>
      <Dialog.Content data-sentry-element="unknown" data-sentry-source-file="subscriptionDialog.tsx">
        <Dialog.Title data-sentry-element="unknown" data-sentry-source-file="subscriptionDialog.tsx">What are quills?</Dialog.Title>
        <Dialog.Description size="3" data-sentry-element="unknown" data-sentry-source-file="subscriptionDialog.tsx">
          You always get unlimited access to our free model, but quills unlock
          paid features that improve your stories and help keep Spellbound
          running.
        </Dialog.Description>
        <div className="flex flex-col gap-2">
          <Separator size="4" className="my-4" data-sentry-element="Separator" data-sentry-source-file="subscriptionDialog.tsx" />
          <div className="flex flex-col gap-2">
            <Heading size="3" className="mb-2" data-sentry-element="Heading" data-sentry-source-file="subscriptionDialog.tsx">
              If you buy quills, you'll automatically unlock access to our paid
              features:
            </Heading>
            <ul className="list-disc list-inside flex flex-col gap-4">
              <li>
                <b>5 quills</b>: Create a page with our most advanced model
                (automatically enabled while you have quills)
              </li>
              <li>
                <b>{superchargeCost} quills</b>: Supercharge your next page,
                making it longer and increasing the AI's memory
              </li>
              <li>
                <b>{audioCost} quills</b>: Narrate your story (Costs{" "}
                {superchargedAudioCost} quills if the page was supercharged)
              </li>
              <li>
                <b>{thinkingCost} quills</b>: Enable our advanced thinking mode,
                making responses smarter and more detailed
              </li>
            </ul>
          </div>
          <Separator size="4" className="my-4" data-sentry-element="Separator" data-sentry-source-file="subscriptionDialog.tsx" />
          <Heading size="4" data-sentry-element="Heading" data-sentry-source-file="subscriptionDialog.tsx">Comparing our models:</Heading>
          <Text size="3" data-sentry-element="Text" data-sentry-source-file="subscriptionDialog.tsx">
            Our free model is smarter than most models out there and creates
            long, detailed replies with great memory. It's just not as smart as
            our paid models.
          </Text>
          <Box my="4" data-sentry-element="Box" data-sentry-source-file="subscriptionDialog.tsx">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left p-2"></th>
                  <th className="text-left p-2">Free Model</th>
                  <th className="text-left p-2">Paid Model</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2">Character Knowledge</td>
                  <td className="p-2">
                    {"★".repeat(3)}
                    {"☆".repeat(2)}
                  </td>
                  <td className="p-2">{"★".repeat(5)}</td>
                </tr>
                <tr>
                  <td className="p-2">Writing Quality</td>
                  <td className="p-2">
                    {"★".repeat(4)}
                    {"☆".repeat(1)}
                  </td>
                  <td className="p-2">{"★".repeat(5)}</td>
                </tr>
                <tr>
                  <td className="p-2">Memory</td>
                  <td className="p-2">
                    {"★".repeat(3)}
                    {"☆".repeat(2)}
                  </td>
                  <td className="p-2">{"★".repeat(5)}</td>
                </tr>
                <tr>
                  <td className="p-2">Speed</td>
                  <td className="p-2">
                    {"★".repeat(2)}
                    {"☆".repeat(3)}
                  </td>
                  <td className="p-2">{"★".repeat(5)}</td>
                </tr>
              </tbody>
            </table>
          </Box>

          <Dialog.Close data-sentry-element="unknown" data-sentry-source-file="subscriptionDialog.tsx">
            <Button variant="solid" color="red" data-sentry-element="Button" data-sentry-source-file="subscriptionDialog.tsx">
              Close
            </Button>
          </Dialog.Close>
        </div>
      </Dialog.Content>
    </Dialog.Root>;
}
export function SubscriptionDialog({
  trigger
}: {
  trigger: React.ReactNode;
}) {
  const [showDialog, setShowDialog] = useState(false);
  const {
    userProfile
  } = useUserProfile();
  const {
    data: subscriptionLink,
    isLoading: subscriptionLinkLoading
  } = trpc.users.getUserSubscriptionLink.useQuery({}, {
    enabled: !!userProfile
  });
  const {
    data: purchaseLinks,
    isLoading: purchaseLinksLoading
  } = trpc.users.getUserPurchaseLink.useQuery({}, {
    enabled: !!userProfile
  });
  const daysUntilReset = subscriptionLink ? Math.ceil((subscriptionLink?.expiresAt.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) : undefined;
  const monthlyQuills = userProfile?.monthlyQuills;
  const {
    openUrl
  } = useOverlay();
  const subscriptionOptions = [{
    quills: 1000,
    pages: 200,
    price: 9.99,
    url: purchaseLinks?.url_1000
  }, {
    quills: 2500,
    pages: 500,
    price: 19.99,
    url: purchaseLinks?.url_2500
  }, {
    quills: 6500,
    pages: 1300,
    price: 49.99,
    url: purchaseLinks?.url_6500
  }];
  return <Dialog.Root open={showDialog} onOpenChange={setShowDialog} data-sentry-element="unknown" data-sentry-component="SubscriptionDialog" data-sentry-source-file="subscriptionDialog.tsx">
      <Dialog.Trigger data-sentry-element="unknown" data-sentry-source-file="subscriptionDialog.tsx">{trigger}</Dialog.Trigger>
      <Dialog.Content data-sentry-element="unknown" data-sentry-source-file="subscriptionDialog.tsx">
        <Dialog.Title data-sentry-element="unknown" data-sentry-source-file="subscriptionDialog.tsx">
          <Heading data-sentry-element="Heading" data-sentry-source-file="subscriptionDialog.tsx">Quills</Heading>
        </Dialog.Title>
        <Dialog.Description size="5" data-sentry-element="unknown" data-sentry-source-file="subscriptionDialog.tsx">
          Unlimited messages are free — Quills enhance your experience.
        </Dialog.Description>
        <QuillCostDialog trigger={<RadixLink href="#" size="4" color="purple" className="mt-2 w-full">
              Learn More <FaExternalLinkAlt className="w-4 h-4 inline-block" />
            </RadixLink>} data-sentry-element="QuillCostDialog" data-sentry-source-file="subscriptionDialog.tsx" />

        <Separator size="4" className="my-4" data-sentry-element="Separator" data-sentry-source-file="subscriptionDialog.tsx" />
        {subscriptionLinkLoading || purchaseLinksLoading ? <Text size="5">Loading...</Text> : <Flex direction="column" gap="7" py="4">
            <div className="flex flex-col gap-1">
              <Text size="7">
                <b>{userProfile?.quills}</b> <small>quills remaining</small>
              </Text>
              {userProfile?.quills && userProfile?.quills < 100 && <Text size="4">
                    (You'll still be able to use Spellbound once your out)
                  </Text>}
              {monthlyQuills != null && monthlyQuills > 0 && <Text size="4">
                  (<b>{monthlyQuills}</b>{" "}
                  <small>
                    of those quills reset when your subscription refreshes
                  </small>
                  )
                </Text>}
            </div>
            <div className="flex flex-col gap-3">
              <Heading size="7">
                Membership{" "}
                <StarsIcon className={"w-5 h-5 inline-block"} alt={"Stars Icon"}></StarsIcon>
              </Heading>
              {!subscriptionLink && <Card variant="classic" className="p-4 border-2 border-[var(--jade-4)] bg-[var(--jade-1)] hover:scale-105 transition-all duration-300" asChild>
                  <Link href={purchaseLinks?.url_membership ?? ""} target="_blank">
                    <Flex direction="column" width="100%" className="items-center gap-1">
                      <Text weight="bold" size="6" color="gold">
                        Spellbound +
                      </Text>
                      <Text size="4">
                        Get 1100 quills that last 30 days, each month
                      </Text>
                      <Text size="5">
                        <b>$9.99 USD</b>
                      </Text>
                    </Flex>
                  </Link>
                </Card>}
              {subscriptionLink && <div className="flex flex-col gap-2">
                  <Text size="5" color={subscriptionLink.isCancelled ? "yellow" : "green"}>
                    {subscriptionLink.isCancelled ? <b>Your subscription has been cancelled</b> : <b>You are subscribed to Spellbound+</b>}
                  </Text>
                  <Text size="5">
                    <ul className="list-disc list-inside">
                      <li>
                        {subscriptionLink.isCancelled ? "You can re-subscribe after " + subscriptionLink.expiresAt.toLocaleDateString() : <>
                            Your quills will reset{" "}
                            {daysUntilReset! > 0 ? <u>
                                <b> in {daysUntilReset} days</b>
                              </u> : <u>
                                <b>tonight</b>
                              </u>}
                          </>}
                      </li>
                      <li>
                        You can purchase more quills, or manage your
                        subscription below
                      </li>
                      <li>
                        If you cancel your subscription, you won't be able to
                        re-subscribe until{" "}
                        {subscriptionLink.expiresAt.toLocaleDateString()}
                      </li>
                    </ul>
                  </Text>
                </div>}
            </div>
            <div>
              <div className="flex flex-col gap-4 items-stretch content-stretch">
                <div className="flex flex-col gap-1">
                  <Heading size="7">One-time purchases</Heading>
                  <Text size="4">(These quills never expire)</Text>
                </div>
                <div className="flex flex-col gap-2 items-stretch content-stretch md:flex-row justify-between">
                  {subscriptionOptions.map((option, index) => <Card variant="classic" className="p-4 border-2 border-[var(--teal-4)] bg-[var(--teal-1)] hover:scale-105 transition-all duration-300" key={index} asChild>
                      <Link href={option.url ?? ""} target="_blank">
                        <Flex direction="column" width="100%" className="items-center gap-1">
                          <Text weight="bold" size="6">
                            {option.quills} quills
                          </Text>
                          <Text size="4">~{option.pages} pages</Text>
                          <Text size="3">
                            <b>${option.price} USD</b>
                          </Text>
                        </Flex>
                      </Link>
                    </Card>)}
                </div>
              </div>
            </div>

            {subscriptionLink && !subscriptionLink.isCancelled && <Button variant="soft" className="py-4" size={{
          sm: "2",
          md: "3",
          lg: "4"
        }}>
                <Link href={subscriptionLink.url}>Manage Subscription</Link>
              </Button>}
            <Dialog.Close>
              <Button variant="solid" color="red" size={{
            sm: "2",
            md: "3",
            lg: "4"
          }}>
                Close
              </Button>
            </Dialog.Close>
          </Flex>}
      </Dialog.Content>
    </Dialog.Root>;
}