import { Flex, Heading } from "@radix-ui/themes";
import StarsIcon from "../../../../public/icons/stars.svg";
export function SpellboundIcon({
  color = "var(--yellow-11)",
  isReponsive = false
}: {
  color?: string;
  isReponsive?: boolean;
}) {
  return <Flex style={{
    height: "100%",
    paddingRight: "-20px"
  }} data-sentry-element="Flex" data-sentry-component="SpellboundIcon" data-sentry-source-file="spellboundIcon.tsx">
      <Heading size={{
      initial: "6",
      sm: "7"
    }} className={isReponsive ? "rt-r-display-none sm:rt-r-display-block" : "rt-r-display-block"} data-sentry-element="Heading" data-sentry-source-file="spellboundIcon.tsx">
        spellbound
      </Heading>
      <Heading size={"6"} className={isReponsive ? "rt-r-display-block sm:rt-r-display-none" : "rt-r-display-none"} data-sentry-element="Heading" data-sentry-source-file="spellboundIcon.tsx">
        S
      </Heading>
      <div style={{
      color: color
    }}>
        <StarsIcon className={"w-5 h-5"} alt={"Stars Icon"} data-sentry-element="StarsIcon" data-sentry-source-file="spellboundIcon.tsx"></StarsIcon>
      </div>
    </Flex>;
}