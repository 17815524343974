import { Box, Button, Dialog, Flex, Link } from "@radix-ui/themes";
import SignInWithGoogle from "@/pages/app/auth/signInWithGoogle";
import SignInWithDiscord from "@/pages/app/auth/signInWithDiscord";
import { ReactNode, useState } from "react";
import SignInWithAppleId from "./signInWithApple";
import { Capacitor } from "@capacitor/core";
import SignInWithEmail from "./signInWithEmail";
export function AuthDialogSignedOutContent({
  redirect,
  title,
  onClose
}: {
  redirect?: string;
  title?: ReactNode;
  onClose?: () => void;
}) {
  return <Dialog.Content size={"4"} className={"rounded-2xl"} data-sentry-element="unknown" data-sentry-component="AuthDialogSignedOutContent" data-sentry-source-file="authDialogSignedOutContent.tsx">
      <Dialog.Title size={"4"} data-sentry-element="unknown" data-sentry-source-file="authDialogSignedOutContent.tsx">Login or Signup</Dialog.Title>
      <Dialog.Description size="3" mb="4" data-sentry-element="unknown" data-sentry-source-file="authDialogSignedOutContent.tsx">
        {title ? title : "Pick one of our sign-in options below!"}
      </Dialog.Description>

      <Flex direction="column" gap="5" my={"4"} mx={"4"} data-sentry-element="Flex" data-sentry-source-file="authDialogSignedOutContent.tsx">
        <Box className={"w-full"} data-sentry-element="Box" data-sentry-source-file="authDialogSignedOutContent.tsx">
          <SignInWithGoogle redirect={redirect} data-sentry-element="SignInWithGoogle" data-sentry-source-file="authDialogSignedOutContent.tsx" />
        </Box>
        <Box className={"w-full"} data-sentry-element="Box" data-sentry-source-file="authDialogSignedOutContent.tsx">
          <SignInWithDiscord redirect={redirect} data-sentry-element="SignInWithDiscord" data-sentry-source-file="authDialogSignedOutContent.tsx" />
        </Box>
        {Capacitor.getPlatform() !== "web" && <Box className={"w-full"}>
            <SignInWithAppleId redirect={redirect} />
          </Box>}
        <Box className={"w-full py-4"} data-sentry-element="Box" data-sentry-source-file="authDialogSignedOutContent.tsx">
          <SignInWithEmail redirect={redirect} data-sentry-element="SignInWithEmail" data-sentry-source-file="authDialogSignedOutContent.tsx" />
        </Box>
        <Flex direction="column" gap="2" data-sentry-element="Flex" data-sentry-source-file="authDialogSignedOutContent.tsx">
          <Link href={"https://tryspellbound.notion.site/Spellbound-Terms-Of-Service-93ddef119a214547acaacaef2d8323e0?pvs=4"} target="_blank" data-sentry-element="Link" data-sentry-source-file="authDialogSignedOutContent.tsx">
            Our Terms of Service and Privacy Policy
          </Link>
        </Flex>
      </Flex>

      <Flex gap="3" mt="4" mx={"4"} justify="end" data-sentry-element="Flex" data-sentry-source-file="authDialogSignedOutContent.tsx">
        <Dialog.Close data-sentry-element="unknown" data-sentry-source-file="authDialogSignedOutContent.tsx">
          <Button variant="surface" color="gray" size={"4"} radius={"full"} onClick={onClose} data-sentry-element="Button" data-sentry-source-file="authDialogSignedOutContent.tsx">
            Cancel
          </Button>
        </Dialog.Close>
      </Flex>
    </Dialog.Content>;
}