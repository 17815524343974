import { FrontendScenario } from "@/backend/frontendScenario";
import { CharacterAvatar } from "@/pages/app/home/characterAvatar";
import { Box, Tooltip } from "@radix-ui/themes";
import Image from "next/image";
export function CharacterAvatarRow({
  scenario,
  loadEarly,
  size,
  enableShortcut = false
}: {
  scenario: FrontendScenario;
  loadEarly: boolean;
  size: string;
  enableShortcut?: boolean;
}) {
  return <>
      {scenario.characters.map((character, index) => {
      if (character.id === 195908) {
        console.log("character", character);
        return <CharacterAvatar size={size} key={character.id} index={index} character={character} loadEarly={loadEarly} enableShortcut={enableShortcut} />;
      }
      return <CharacterAvatar size={size} key={character.id} index={index} character={character} loadEarly={loadEarly} enableShortcut={enableShortcut} />;
    })}
    </>;
}