import { useTheme } from "next-themes";
import { Box, Flex, IconButton, TextField, Strong } from "@radix-ui/themes";
import Link from "next/link";
import { SpellboundIcon } from "@/pages/app/home/spellboundIcon";
import { FaDiscord, FaMagnifyingGlass } from "react-icons/fa6";
import { StoryDrawer } from "@/pages/app/home/storyDrawer";
import { AuthDialog } from "@/pages/app/auth/authDialog";
import { FaMoon, FaSun } from "react-icons/fa";
import { useAnalytics } from "@/analyticsContext";
export function GlobalHeader({
  onChange,
  onCheckedChange,
  enableSearch,
  onClickSpellbound,
  lowContrast = false,
  additionalContent
}: {
  onCheckedChange?: (checked) => void;
  onChange?: (e) => void;
  onClickSpellbound?: () => void;
  enableSearch: boolean;
  lowContrast?: boolean;
  additionalContent?: React.ReactNode;
}) {
  const analytics = useAnalytics();
  return <Flex style={{
    width: "100%",
    maxWidth: "100svw",
    position: "relative"
  }} direction={"column"} align={"center"} justify={"center"} gap={"3"} data-sentry-element="Flex" data-sentry-component="GlobalHeader" data-sentry-source-file="globalHeader.tsx">
      <Flex className={"w-full h-full"} justify={"center"} align="center" direction={"row"} data-sentry-element="Flex" data-sentry-source-file="globalHeader.tsx">
        <Flex asChild onClick={() => onClickSpellbound?.()} className={lowContrast ? "hover:opacity-100 opacity-50 transition-opacity" : undefined} align={"center"} gap="2" style={{
        pointerEvents: "auto",
        zIndex: 10,
        height: "100%",
        cursor: !!onClickSpellbound ? "pointer" : "default"
      }} data-sentry-element="Flex" data-sentry-source-file="globalHeader.tsx">
          <Link href={"/"} data-sentry-element="Link" data-sentry-source-file="globalHeader.tsx">
            <SpellboundIcon isReponsive={true} data-sentry-element="SpellboundIcon" data-sentry-source-file="globalHeader.tsx" />
          </Link>
        </Flex>

        <Flex direction={"column"} align={"center"} width={"100%"} justify={"center"} data-sentry-element="Flex" data-sentry-source-file="globalHeader.tsx">
          <Flex align={"center"} justify={"center"} height={"wrap-content"} style={{
          boxSizing: "border-box",
          marginLeft: "auto"
        }} data-sentry-element="Flex" data-sentry-source-file="globalHeader.tsx">
            {enableSearch && <TextField.Root size={"3"} className={"min-w-0 hidden sm:flex"} onChange={onChange} aria-label="Search" placeholder="Search">
                <TextField.Slot>
                  <FaMagnifyingGlass aria-hidden="true" />
                </TextField.Slot>
              </TextField.Root>}
            {additionalContent && <Box mx={"2"} />}
            {additionalContent}
            <Box mx={"2"} data-sentry-element="Box" data-sentry-source-file="globalHeader.tsx" />
            <StoryDrawer data-sentry-element="StoryDrawer" data-sentry-source-file="globalHeader.tsx" />
            <Box mx={"2"} data-sentry-element="Box" data-sentry-source-file="globalHeader.tsx" />
            <AuthDialog data-sentry-element="AuthDialog" data-sentry-source-file="globalHeader.tsx" />
            <Box mx={"2"} data-sentry-element="Box" data-sentry-source-file="globalHeader.tsx" />
            <IconButton variant={"outline"} size={"2"} radius={"full"} onClick={() => {
            analytics.capture("ui.discordClicked", {});
            window.open("https://discord.gg/spellbound", "_blank");
          }} data-sentry-element="IconButton" data-sentry-source-file="globalHeader.tsx">
              <FaDiscord data-sentry-element="FaDiscord" data-sentry-source-file="globalHeader.tsx" />
            </IconButton>
          </Flex>
        </Flex>
      </Flex>
      {enableSearch && <TextField.Root size={"3"} className={"visible sm:hidden ml-auto mr-auto w-full"} onChange={onChange}>
          <TextField.Slot>
            <FaMagnifyingGlass />
          </TextField.Slot>
        </TextField.Root>}
    </Flex>;
}