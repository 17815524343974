import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { Box, Button, Card, Checkbox, Flex, Heading, Link,
// ScrollArea, // No longer needed for the list itself
Separator, Text, TextField, Spinner, ScrollArea } from "@radix-ui/themes";
// --- Remove react-window related imports ---
// import { VariableSizeList as List } from "react-window";
// import InfiniteLoader from "react-window-infinite-loader";
// import AutoSizer from "react-virtualized-auto-sizer";
// --- Add react-virtuoso imports ---
import { GroupedVirtuoso, GroupedVirtuosoHandle } from 'react-virtuoso';
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { trpc } from "@/utils/trpc";
import { RenderableConversation } from "@/pages/api/trpc/conversations/conversationProcedures";
import Image from "next/image";
import { imageKitLoader } from "@/utils/imageKitLoader";
import { CharacterAvatarRow } from "@/pages/app/home/characterAvatarRow";
import { toFrontendScenario } from "@/pages/api/trpc/scenarios/toFrontendScenario";
import { useRouterUtils } from "@/app/routerUtils";
import { useDebounce } from "@uidotdev/usehooks";
import { useAnalytics } from "@/analyticsContext";
const ITEMS_PER_PAGE = 20;

// --- Grouping function remains the same ---
// (Keep the existing groupConversationsByAge implementation - no changes needed)
const groupConversationsByAge = (conversations: RenderableConversation[], groups: number[], timePeriodsForGrouping // Pass timePeriods explicitly
: [string, number][]): {
  label: string;
  conversations: RenderableConversation[];
}[] => {
  if (!conversations) return [];
  const now = Date.now();
  const groupedData: RenderableConversation[][] = groups.map(() => []);
  const timeLabels = timePeriodsForGrouping.map(([label]) => label); // Get labels based on input
  let olderGroupIndex = -1; // Track potential "Older" group

  for (const conversation of conversations) {
    const lastModified = conversation.conversation.updatedAt ?? conversation.conversation.createdAt;
    const age = now - lastModified.getTime();
    const groupIndex = groups.findIndex(groupAge => age <= groupAge);
    if (groupIndex !== -1) {
      groupedData[groupIndex].push(conversation);
    } else {
      // Older than all defined groups
      if (olderGroupIndex === -1) {
        // Create the "Older" group if it doesn't exist
        olderGroupIndex = groupedData.length;
        groupedData.push([]);
        if (timeLabels.length === groups.length) {
          // Add label only if not already present (like in search)
          timeLabels.push("Older");
        }
      }
      groupedData[olderGroupIndex].push(conversation);
    }
  }

  // Map to the final structure with labels and filter empty groups
  return groupedData.map((groupConversations, index) => ({
    label: timeLabels[index] ?? "Older",
    // Use corresponding label or default to "Older"
    conversations: groupConversations
  })).filter(group => group.conversations.length > 0);
};

// --- StoryDrawerListItem remains the same ---
// (Keep the existing StoryDrawerListItem implementation - no changes needed)
function StoryDrawerListItem({
  conversation,
  onClick
}: {
  conversation: RenderableConversation;
  onClick?: () => void;
}) {
  return <Card asChild size={"1"} variant={"ghost"} className={"w-full h-16"} style={{
    margin: 0,
    filter: "none",
    position: "relative",
    backdropFilter: "none !important",
    overflow: "hidden" // Added for better containment if needed
  }} data-sentry-element="Card" data-sentry-component="StoryDrawerListItem" data-sentry-source-file="storyDrawer.tsx">
      <a href="#" onClick={e => {
      e.preventDefault();
      onClick?.();
    }}>
        {/* Background Image & Gradient */}
        <Image loader={imageKitLoader} fill className={"object-cover absolute inset-0 w-full h-full"} loading={"lazy"} // Lazy load images below the fold
      sizes="150px" // Adjust size based on drawer width
      src={conversation.scenario.previewImage} alt={conversation.scenario.background ?? `A sketch for a story named ${conversation.scenario.title}`} style={{
        // Adjust gradient for better text visibility maybe
        mask: "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, .3))",
        zIndex: 1 // Ensure image is behind text
      }} data-sentry-element="Image" data-sentry-source-file="storyDrawer.tsx" />
        {/* Character Avatars (might need adjustment based on final layout) */}
        <Box style={{
        top: "120%",
        // This seems unusual, might need review depending on desired effect
        left: 0,
        right: 0,
        bottom: 0,
        position: "absolute",
        zIndex: 2 // Above image, below text?
      }} data-sentry-element="Box" data-sentry-source-file="storyDrawer.tsx">
          <Box width={"100%"} height={"100%"} data-sentry-element="Box" data-sentry-source-file="storyDrawer.tsx">
            <CharacterAvatarRow scenario={toFrontendScenario(conversation)} loadEarly={true} // Consider if still needed, lazy loading might be enough
          size={"90px"} // Check if this size works well in the list item
          data-sentry-element="CharacterAvatarRow" data-sentry-source-file="storyDrawer.tsx" />
          </Box>
        </Box>
        {/* Text Content */}
        <Flex className={"h-full w-full z-10"} align={"center"} style={{
        position: 'relative',
        zIndex: 3
      }} data-sentry-element="Flex" data-sentry-source-file="storyDrawer.tsx"> {/* Ensure text is on top */}
          <Text size={"3"} className={"z-10 w-full line-clamp-2 px-4"} // Use padding on Text or parent Flex
        align={"right"} style={{
          textShadow:
          // Kept the complex text shadow
          "0px 0px 2px var(--mauve-2), 0px 0px 4px var(--mauve-2), 0px 0px 10px var(--mauve-2), 0px 0px 10px var(--mauve-2), 0px 0px 3px var(--mauve-2), 0px 0px 3px var(--mauve-2), 0px 0px 3px var(--mauve-2), 0px 0px 3px var(--mauve-2), 0px 0px 3px var(--mauve-2),0px 0px 2px var(--mauve-2), 0px 0px 4px var(--mauve-2), 0px 0px 10px var(--mauve-2), 0px 0px 10px var(--mauve-2), 0px 0px 3px var(--mauve-2), 0px 0px 3px var(--mauve-2), 0px 0px 3px var(--mauve-2), 0px 0px 3px var(--mauve-2), 0px 0px 3px var(--mauve-2)"
        }} data-sentry-element="Text" data-sentry-source-file="storyDrawer.tsx">
            {conversation.conversation.name ?? conversation.scenario.title}
          </Text>
        </Flex>
      </a>
    </Card>;
}
export function StoryDrawer() {
  const [isOpen, setIsOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedStories, setSelectedStories] = useState<RenderableConversation["conversation"]["id"][] // Store only IDs for selection
  >([]);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  const analytics = useAnalytics();
  const trpcUtils = trpc.useUtils();
  const {
    openStory
  } = useRouterUtils();
  const virtuosoRef = useRef<GroupedVirtuosoHandle>(null); // Ref for Virtuoso

  const toggleDrawer = () => {
    setIsOpen(prevState => {
      if (prevState) {
        setEditMode(false);
        setSelectedStories([]);
      }
      analytics.capture("storyDrawer.storyDrawerToggled", {
        open: !prevState
      });
      return !prevState;
    });
  };
  const storiesQuery = trpc.conversations.getConversations.useInfiniteQuery({
    searchTerm: debouncedSearch,
    limit: ITEMS_PER_PAGE
  }, {
    getNextPageParam: lastPage => lastPage.nextCursor,
    initialCursor: 0,
    refetchOnWindowFocus: false
    // Keep stale time if desired, e.g., staleTime: 5 * 60 * 1000
  });

  // Flatten pages data - remains the same
  const flattenedStories = useMemo(() => storiesQuery.data?.pages.flatMap(page => page.items) ?? [], [storiesQuery.data]);

  // Prepare data for GroupedVirtuoso
  const {
    groups,
    groupCounts
  } = useMemo(() => {
    // Define time periods based on search
    const currentPeriods: [string, number][] = debouncedSearch ? [["Search Results", Infinity]] // Single group for search results
    : [["Today", 86400000], ["Yesterday", 172800000], ["Previous 7 Days", 604800000], ["This Month", 2592000000]
    // "Older" label is handled dynamically by grouping function
    ];

    // Numeric ages for grouping logic
    const numericAges = currentPeriods.map(([, age]) => age).filter(age => age !== Infinity);
    const grouped = groupConversationsByAge(flattenedStories, numericAges, currentPeriods);
    return {
      groups: grouped,
      // Array of { label: string, conversations: RenderableConversation[] }
      groupCounts: grouped.map(g => g.conversations.length) // Array of numbers (count per group)
    };
  }, [flattenedStories, debouncedSearch]);

  // --- Infinite Loading Callback for Virtuoso ---
  const loadMore = useCallback(() => {
    if (!storiesQuery.isFetchingNextPage && storiesQuery.hasNextPage) {
      storiesQuery.fetchNextPage();
    }
  }, [storiesQuery.fetchNextPage, storiesQuery.hasNextPage, storiesQuery.isFetchingNextPage]);
  const deleteStories = trpc.conversations.deleteConversations.useMutation({
    onSuccess: () => {
      // Invalidate the infinite query cache to refetch data
      trpcUtils.conversations.getConversations.invalidate();
      setSelectedStories([]);
      setEditMode(false);
      // Maybe scroll to top after delete?
      // virtuosoRef.current?.scrollToIndex({ index: 0, align: 'start' });
    },
    onError: error => {
      console.error("Error deleting stories:", error);
      // Add user feedback (e.g., toast notification)
    }
  });

  // Reset list scroll when search term changes
  useEffect(() => {
    virtuosoRef.current?.scrollToIndex({
      index: 0,
      align: 'start'
    });
  }, [debouncedSearch]);

  // Lock body scroll when open - remains the same
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      // Return cleanup function
      return () => {
        document.body.style.overflow = "";
      };
    }
  }, [isOpen]);

  // Helper to get conversation by overall index (needed for selection logic)
  const getConversationByIndex = (index: number): RenderableConversation | null => {
    let count = 0;
    for (const group of groups) {
      if (index < count + group.conversations.length) {
        return group.conversations[index - count];
      }
      count += group.conversations.length;
    }
    return null; // Should not happen if index is valid
  };
  const handleItemClick = async (conversation: RenderableConversation) => {
    if (editMode) {
      const id = conversation.conversation.id;
      setSelectedStories(prev => prev.includes(id) ? prev.filter(selectedId => selectedId !== id) : [...prev, id]);
    } else {
      await openStory(conversation.conversation.id);
      setIsOpen(false); // Close drawer after opening story
    }
  };
  const handleCheckboxChange = (checked: boolean, conversation: RenderableConversation) => {
    const id = conversation.conversation.id;
    if (checked) {
      setSelectedStories(prev => [...prev, id]);
    } else {
      setSelectedStories(prev => prev.filter(selectedId => selectedId !== id));
    }
  };
  const handleDeleteClick = async () => {
    if (selectedStories.length === 0) return;
    analytics.capture("storyDrawer.deleteStory", {
      storyCount: selectedStories.length
    });
    await deleteStories.mutateAsync({
      conversationIds: selectedStories
    });
  };
  return <>
      <Link onClick={toggleDrawer} underline={"always"} color={"gray"} highContrast className={"cursor-pointer"} size={"3"} aria-label="My stories" href="#" data-sentry-element="Link" data-sentry-source-file="storyDrawer.tsx">
        My stories {/* Use non-breaking space */}
      </Link>
      <Drawer open={isOpen} onClose={toggleDrawer} direction="right" customIdSuffix="story-drawer"
    // lockBackgroundScroll handled by useEffect
    style={{
      backgroundColor: "transparent",
      boxShadow: "none",
      display: isOpen ? "flex" : "none",
      // Use flex for Card height
      paddingTop: "env(safe-area-inset-top)",
      paddingBottom: "env(safe-area-inset-bottom)",
      zIndex: 1000000,
      // Ensure high z-index
      // Set width directly here or on the Card
      width: 'clamp(250px, 30%, 400px)',
      // Example responsive width
      maxWidth: '100vw' // Ensure it doesn't overflow viewport
    }} data-sentry-element="Drawer" data-sentry-source-file="storyDrawer.tsx">
        {isOpen &&
      // Conditional render reduces work when closed
      <Card className={"h-full w-full flex flex-col"} data-testid="story-drawer-card"> {/* Use flex column */}
            <Flex direction={"column"} className="p-3" gap={"3"}> {/* Add padding here */}
                {/* Search Input */}
                <TextField.Root placeholder="Search your stories..." value={search} onChange={e => setSearch(e.target.value)} onFocus={() => {
            analytics.capture("storyDrawer.searchFocused", {});
          }} />

                {/* Separator */}
                <Separator size={"4"} />
            </Flex>

            {/* Virtualized List Area */}
            <Box style={{
          flex: 1,
          minHeight: 0,
          position: "relative"
        }} className="px-0"> {/* Use flex: 1 and minHeight: 0 */}
              {storiesQuery.isLoading && !storiesQuery.isFetchingNextPage && <Flex align="center" justify="center" className="absolute inset-0">
                  <Spinner size="3" />
                </Flex>}
              {storiesQuery.isError && <Flex align="center" justify="center" className="absolute inset-0" direction="column" gap="2">
                  <Text color="tomato">Error loading stories.</Text>
                  <Button onClick={() => storiesQuery.refetch()} color="gray" variant="soft" size="1">Retry</Button>
                </Flex>}
              {!storiesQuery.isLoading && !storiesQuery.isError && flattenedStories.length === 0 && <Flex align="center" justify="center" className="absolute inset-0">
                  <Text color="gray">
                    {debouncedSearch ? `No stories found for "${debouncedSearch}".` : "No stories yet."}
                  </Text>
                </Flex>}

              {/* Render Virtuoso only when there's data or it's loading more */}
              {(flattenedStories.length > 0 || storiesQuery.hasNextPage || storiesQuery.isFetchingNextPage) && !storiesQuery.isLoading && !storiesQuery.isError && <GroupedVirtuoso ref={virtuosoRef} style={{
            height: '100%',
            width: '100%'
          }} // Let Virtuoso fill the Box
          groupCounts={groupCounts} groupContent={index => <Box py="1" px="2" className="backdrop-blur-sm dark:bg-black/50 bg-black/50 text-white  rounded-sm"> {/* Add padding to group header */}
                        <Heading size="3">{groups[index].label}</Heading>
                    </Box>} itemContent={index => {
            const conversation = getConversationByIndex(index); // Get the correct conversation
            if (!conversation) return null; // Should not happen

            const isSelected = selectedStories.includes(conversation.conversation.id);
            return <Flex align={"center"} gap={"2"} py="1" px="2"> {/* Add padding around each item */}
                           {editMode && <Checkbox size="3" checked={isSelected}
              // Use form control approach or direct state update
              onCheckedChange={checked => handleCheckboxChange(Boolean(checked), conversation)} variant={"classic"} color={"red"} aria-label={`Select story ${conversation.conversation.name ?? conversation.scenario.title}`} />}
                           {/* Use a div wrapper if needed for layout, or apply width directly */}
                           <Box style={{
                flexGrow: 1
              }}>
                             <StoryDrawerListItem conversation={conversation} onClick={() => handleItemClick(conversation)} />
                           </Box>
                         </Flex>;
          }} endReached={loadMore} // Trigger loading more data
          overscan={200} // Adjust overscan as needed (pixels)
          components={{
            Footer: () =>
            // Footer for loading indicator
            storiesQuery.isFetchingNextPage ? <Flex align="center" justify="center" p="3">
                          <Spinner size="3" />
                        </Flex> : null // No footer when not loading
          }}
          // Add a key that changes when search changes, forcing a full remount/reset
          key={debouncedSearch} />}
            </Box>

            {/* Bottom Actions Section */}
            <Flex direction={"column"} className="p-3 mt-auto" gap={"3"}> {/* Add padding here, mt-auto pushes to bottom */}
                 {/* Separator */}
                <Separator size={"4"} />
                {/* Action Buttons */}
                {editMode ? <>
                    {selectedStories.length > 0 ? <Flex width={"100%"} gap={"2"}>
                        <Button className={"flex-grow"} variant={"soft"} loading={deleteStories.isPending} onClick={handleDeleteClick} color={"tomato"} disabled={deleteStories.isPending || selectedStories.length === 0}>
                          Delete {selectedStories.length} Stor{selectedStories.length === 1 ? "y" : "ies"}
                        </Button>
                        <Button variant="outline" onClick={() => {
                setEditMode(false);
                setSelectedStories([]);
              }} disabled={deleteStories.isPending}>
                          Cancel
                        </Button>
                      </Flex> : <Button variant={"outline"} onClick={() => setEditMode(false)}>
                        Done Editing
                      </Button>}
                  </> : <Button variant={"outline"} onClick={() => {
            analytics.capture("storyDrawer.editModeToggled", {
              open: true
            });
            setEditMode(true);
            setSelectedStories([]);
          }} disabled={flattenedStories.length === 0 || storiesQuery.isLoading}>
                    Edit Stories
                  </Button>}
            </Flex>
          </Card>}
      </Drawer>
    </>;
}