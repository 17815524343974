// Import React
import React from "react";
// Import the CSS module
import styles from "./signInButton.module.css";
import { useSupabaseClient } from "@/hooks/useUser";
import { getBaseAppUrl } from "@/pages/getBaseAppUrl";
import { Capacitor } from "@capacitor/core";
import { toast } from "sonner";
import { Browser } from "@capacitor/browser";
function SignInWithGoogle({
  redirect
}: {
  redirect?: string;
}) {
  const supabase = useSupabaseClient();
  const finalRedirect = redirect ?? "/app/home";
  const isWeb = Capacitor.getPlatform() === "web";
  return <button className={styles.gsiMaterialButton} style={{
    width: "100%"
  }} onClick={() => {
    supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: isWeb ? `${window.location.origin}/auth/redirect?actualTarget=${encodeURI(window.location.href)}` : getBaseAppUrl() + "/auth/inAppRedirect",
        skipBrowserRedirect: true
      }
    }).then(({
      data: {
        provider,
        url
      },
      error
    }) => {
      if (Capacitor.getPlatform() === "web") {
        window.location.assign(url!);
      } else {
        toast("Opening Discord in a new tab" + "width=600,height");
        Browser.open({
          url: url!
        });
      }
    });
  }} data-sentry-component="SignInWithGoogle" data-sentry-source-file="signInWithGoogle.tsx">
      <div className={styles.gsiMaterialButtonState}></div>
      <div className={styles.gsiMaterialButtonContentWrapper}>
        <div className={styles.gsiMaterialButtonIcon}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlnsXlink="http://www.w3.org/1999/xlink" style={{
          display: "block"
        }} data-sentry-element="svg" data-sentry-source-file="signInWithGoogle.tsx">
            <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z" data-sentry-element="path" data-sentry-source-file="signInWithGoogle.tsx"></path>
            <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z" data-sentry-element="path" data-sentry-source-file="signInWithGoogle.tsx"></path>
            <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z" data-sentry-element="path" data-sentry-source-file="signInWithGoogle.tsx"></path>
            <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z" data-sentry-element="path" data-sentry-source-file="signInWithGoogle.tsx"></path>
            <path fill="none" d="M0 0h48v48H0z" data-sentry-element="path" data-sentry-source-file="signInWithGoogle.tsx"></path>
          </svg>
        </div>
        <span className={styles.gsiMaterialButtonContents}>
          Continue with Google
        </span>
        <span style={{
        display: "none"
      }}>Continue with Google</span>
      </div>
    </button>;
}
export default SignInWithGoogle;