import { Button, Dialog, Flex, Select, Spinner, Switch, Separator, Text, Heading, Box, Strong } from "@radix-ui/themes";
import { useUserProfile } from "@/utils/auth/useUserProfile";
import { trpc } from "@/utils/trpc";
import { FontType, UiThemeType } from "@/db/enumTypes";
import { fontEnum, themeEnum } from "@/db/schema";
import { useEffect, useState } from "react";
import { SubscriptionDialog } from "../story/[conversationId]/subscriptionDialog";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { useSupabaseClient, useUser } from "@/hooks/useUser";
import { useAnalytics } from "@/analyticsContext";
import { useTheme } from "next-themes";
import { FaMoon, FaSun } from "react-icons/fa6";
import { ImQuill } from "react-icons/im";
function NewComponent({
  blockSensitiveContent,
  onRequestedOff
}: {
  blockSensitiveContent: boolean;
  onRequestedOff: () => Promise<void>;
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  return <Dialog.Root open={open} onOpenChange={setOpen} data-sentry-element="unknown" data-sentry-component="NewComponent" data-sentry-source-file="authDialogSignedInContent.tsx">
      <Dialog.Trigger data-sentry-element="unknown" data-sentry-source-file="authDialogSignedInContent.tsx">
        <Switch checked={blockSensitiveContent ?? false} data-sentry-element="Switch" data-sentry-source-file="authDialogSignedInContent.tsx" />
      </Dialog.Trigger>
      <Dialog.Content data-sentry-element="unknown" data-sentry-source-file="authDialogSignedInContent.tsx">
        <Dialog.Title data-sentry-element="unknown" data-sentry-source-file="authDialogSignedInContent.tsx">Block sensitive content</Dialog.Title>
        <Dialog.Description data-sentry-element="unknown" data-sentry-source-file="authDialogSignedInContent.tsx">
          By default Spellbound hides content that may be considered sensitive.
          <br />
          <br />
          If you turn off this setting, you may see content that is not suitable
          for all users.
          <br />
          <br />
          We recommend keeping this setting <Strong data-sentry-element="Strong" data-sentry-source-file="authDialogSignedInContent.tsx">On</Strong>
        </Dialog.Description>
        <Flex direction={"column"} gap="3" data-sentry-element="Flex" data-sentry-source-file="authDialogSignedInContent.tsx">
          <Text data-sentry-element="Text" data-sentry-source-file="authDialogSignedInContent.tsx"></Text>
          <Button onClick={async () => {
          setLoading(true);
          await onRequestedOff();
          setLoading(false);
          setOpen(false);
        }} variant="surface" size={"4"} color="red" loading={loading} radius={"full"} data-sentry-element="Button" data-sentry-source-file="authDialogSignedInContent.tsx">
            Turn Off
          </Button>
          <Dialog.Close data-sentry-element="unknown" data-sentry-source-file="authDialogSignedInContent.tsx">
            <Button variant="surface" color="gray" size={"4"} radius={"full"} data-sentry-element="Button" data-sentry-source-file="authDialogSignedInContent.tsx">
              Close
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>;
}
function Content() {
  const {
    userProfile,
    refetch
  } = useUserProfile();
  const isSubscribed = userProfile?.isSubscriber;
  const updateUserTheme = trpc.users.updateTheme.useMutation();
  const updatePreferredFont = trpc.users.updatePreferredFont.useMutation();
  const allowAdult = trpc.users.updateAllowAdult.useMutation();
  const blockSensitiveContent = !userProfile?.allowAdult;
  const analytics = useAnalytics();
  const theme = useTheme();
  return <Flex direction="column" my="4" gap="3" data-sentry-element="Flex" data-sentry-component="Content" data-sentry-source-file="authDialogSignedInContent.tsx">
      <Flex direction="column" my="4" gap="3" data-sentry-element="Flex" data-sentry-source-file="authDialogSignedInContent.tsx">
        <Flex align={"center"} gap="2" data-sentry-element="Flex" data-sentry-source-file="authDialogSignedInContent.tsx">
          <Heading size={"5"} data-sentry-element="Heading" data-sentry-source-file="authDialogSignedInContent.tsx">More Settings</Heading>
          {(updateUserTheme.isPending || allowAdult.isPending) && <Spinner />}
        </Flex>
        <Flex direction={"row"} data-sentry-element="Flex" data-sentry-source-file="authDialogSignedInContent.tsx">
          <Button variant={"outline"} size={{
          sm: "2",
          md: "3"
        }} radius={"full"} onClick={() => {
          const newTheme = theme.theme == "dark" ? "light" : "dark";
          analytics.capture("ui.toggleDarkMode", {
            selected: newTheme
          });
          return theme.setTheme(newTheme);
        }} aria-label={`Switch to ${theme.theme === "dark" ? "light" : "dark"} mode`} data-sentry-element="Button" data-sentry-source-file="authDialogSignedInContent.tsx">
            {theme.theme !== "dark" ? <span className="flex items-center gap-2">
                <FaSun aria-hidden="true" /> Switch to Dark Mode
              </span> : <span className="flex items-center gap-2">
                <FaMoon aria-hidden="true" /> Switch to Light Mode
              </span>}
          </Button>
        </Flex>
        <Flex direction="row" gap="2" align={"center"} data-sentry-element="Flex" data-sentry-source-file="authDialogSignedInContent.tsx">
          <Text data-sentry-element="Text" data-sentry-source-file="authDialogSignedInContent.tsx">Change your font</Text>
          <Select.Root disabled={!isSubscribed} value={userProfile?.preferredFont} onValueChange={async (value: string) => {
          await updatePreferredFont.mutateAsync({
            preferredFont: value as FontType
          });
          refetch();
        }} data-sentry-element="unknown" data-sentry-source-file="authDialogSignedInContent.tsx">
            <Flex data-sentry-element="Flex" data-sentry-source-file="authDialogSignedInContent.tsx">
              <Select.Trigger mr="auto" data-sentry-element="unknown" data-sentry-source-file="authDialogSignedInContent.tsx" />
              <Spinner loading={updatePreferredFont.isPending} data-sentry-element="Spinner" data-sentry-source-file="authDialogSignedInContent.tsx" />
            </Flex>
            <Select.Content data-sentry-element="unknown" data-sentry-source-file="authDialogSignedInContent.tsx">
              {fontEnum.enumValues.map(theme => <Select.Item key={theme} value={theme}>
                  {theme}
                </Select.Item>)}
            </Select.Content>
          </Select.Root>
        </Flex>
        <Flex direction="row" gap="2" align={"center"} data-sentry-element="Flex" data-sentry-source-file="authDialogSignedInContent.tsx">
          <Text data-sentry-element="Text" data-sentry-source-file="authDialogSignedInContent.tsx">Block sensitive content</Text>
          {blockSensitiveContent ? <NewComponent blockSensitiveContent={blockSensitiveContent} onRequestedOff={async () => {
          await allowAdult.mutateAsync({
            allowAdult: true
          });
          refetch();
        }} /> : <Switch checked={blockSensitiveContent ?? false} onCheckedChange={async (value: boolean) => {
          await allowAdult.mutateAsync({
            allowAdult: !value
          });
          refetch();
        }} />}
        </Flex>
      </Flex>
      <Flex direction="column" my="4" gap="3" data-sentry-element="Flex" data-sentry-source-file="authDialogSignedInContent.tsx">
        <Heading data-sentry-element="Heading" data-sentry-source-file="authDialogSignedInContent.tsx">Subscribers only</Heading>
        <Flex direction={"column"} gap="3" style={{
        opacity: isSubscribed ? 1 : 0.7
      }} data-sentry-element="Flex" data-sentry-source-file="authDialogSignedInContent.tsx">
          <Flex direction="row" gap="2" align={"center"} data-sentry-element="Flex" data-sentry-source-file="authDialogSignedInContent.tsx">
            <Text data-sentry-element="Text" data-sentry-source-file="authDialogSignedInContent.tsx">
              <Strong data-sentry-element="Strong" data-sentry-source-file="authDialogSignedInContent.tsx">Check your story settings for 'Control Mode'</Strong>
            </Text>
          </Flex>
          <Flex direction="row" gap="2" align={"center"} data-sentry-element="Flex" data-sentry-source-file="authDialogSignedInContent.tsx">
            <Text data-sentry-element="Text" data-sentry-source-file="authDialogSignedInContent.tsx">Change your theme</Text>
            <Select.Root disabled={!isSubscribed} value={userProfile?.preferredTheme} onValueChange={async (value: string) => {
            await updateUserTheme.mutateAsync({
              theme: value as UiThemeType
            });
            refetch();
          }} data-sentry-element="unknown" data-sentry-source-file="authDialogSignedInContent.tsx">
              <Flex data-sentry-element="Flex" data-sentry-source-file="authDialogSignedInContent.tsx">
                <Select.Trigger mr="auto" data-sentry-element="unknown" data-sentry-source-file="authDialogSignedInContent.tsx" />
                <Spinner loading={updateUserTheme.isPending} data-sentry-element="Spinner" data-sentry-source-file="authDialogSignedInContent.tsx" />
              </Flex>
              <Select.Content data-sentry-element="unknown" data-sentry-source-file="authDialogSignedInContent.tsx">
                {themeEnum.enumValues.map(theme => <Select.Item key={theme} value={theme}>
                    {theme}
                  </Select.Item>)}
              </Select.Content>
            </Select.Root>
          </Flex>
        </Flex>
      </Flex>
    </Flex>;
}
export function AuthDialogSignedInContent() {
  const supabase = useSupabaseClient();
  const user = useUser();
  const {
    userProfile
  } = useUserProfile();
  return <Dialog.Content size={"4"} className={"rounded-2xl"} data-sentry-element="unknown" data-sentry-component="AuthDialogSignedInContent" data-sentry-source-file="authDialogSignedInContent.tsx">
      <Dialog.Title size={{
      sm: "5",
      md: "6",
      lg: "7"
    }} data-sentry-element="unknown" data-sentry-source-file="authDialogSignedInContent.tsx">
        Your Account
      </Dialog.Title>
      <Dialog.Description size={{
      sm: "3",
      md: "4",
      lg: "5"
    }} mb="4" className="flex flex-col gap-1" data-sentry-element="unknown" data-sentry-source-file="authDialogSignedInContent.tsx">
        <small>You're currently signed in as</small> <b>{user?.email}</b>
        <Text size={{
        sm: "4",
        md: "4",
        lg: "5"
      }} as="span" data-sentry-element="Text" data-sentry-source-file="authDialogSignedInContent.tsx">
          <small>You have</small> <b>{userProfile?.quills}</b>{" "}
          <small>quills remaining</small>
        </Text>
      </Dialog.Description>

      <SubscriptionDialog trigger={<Button variant="solid" m="0" size={{
      sm: "2",
      md: "3"
    }} radius={"full"} className="p-5">
            <ImQuill className="inline-block" /> Manage Your Quills, Or Learn
            More
          </Button>} data-sentry-element="SubscriptionDialog" data-sentry-source-file="authDialogSignedInContent.tsx" />
      <Content data-sentry-element="Content" data-sentry-source-file="authDialogSignedInContent.tsx" />
      <Flex gap="3" mt="4" mx={"4"} justify="end" data-sentry-element="Flex" data-sentry-source-file="authDialogSignedInContent.tsx">
        <Dialog.Close data-sentry-element="unknown" data-sentry-source-file="authDialogSignedInContent.tsx">
          <Flex gap={"4"} data-sentry-element="Flex" data-sentry-source-file="authDialogSignedInContent.tsx">
            <Button variant="surface" color="red" size={{
            sm: "2",
            md: "4"
          }} radius={"full"} onClick={() => {
            supabase.auth.signOut().then(() => {
              window.location.reload();
            }).catch(error => {
              console.error(error);
            });
          }} data-sentry-element="Button" data-sentry-source-file="authDialogSignedInContent.tsx">
              Logout
            </Button>
            <Button variant="surface" color="gray" size={{
            sm: "2",
            md: "4"
          }} radius={"full"} data-sentry-element="Button" data-sentry-source-file="authDialogSignedInContent.tsx">
              Close
            </Button>
          </Flex>
        </Dialog.Close>
      </Flex>
    </Dialog.Content>;
}