import { generateReferralCode } from "@/shared/referral";
import { useUserProfile } from "@/utils/auth/useUserProfile";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
export function useRouterUtils() {
  const router = useRouter();
  const {
    userProfile
  } = useUserProfile();
  const goHome = () => router.push("/app/home");
  const openStory = (storyId: number) => router.push(`/app/story/${storyId}/edit`);
  const openCharacter = (characterId: number) => router.push(`/app/characters/${characterId}`);
  const urlForScenario = (scenarioId: number) => {
    const userId = userProfile?.id;
    return `${typeof window !== "undefined" ? window.location.origin : ""}/app/scenario/${scenarioId}/create`;
  };
  return {
    goHome,
    openStory,
    openCharacter,
    urlForScenario
  };
}