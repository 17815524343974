import { ImageLoaderProps } from "next/dist/shared/lib/image-config";

export const imageKitLoader = ({ src, width, quality }: ImageLoaderProps) => {
  if(!src.includes("imagekit")) return src;
  if(src.includes("?")) return src;
  if (src[0] === "/") src = src.slice(1);

  const params = [`w-${width}`];
  if (quality) {
    params.push(`q-${quality}`);
  }
  const paramsString = params.join(",");
  return `${src}?tr=${paramsString}`;
};

export default function imageKitLoaderDefault({ src, width, quality } : ImageLoaderProps) {
  return imageKitLoader({ src, width, quality });
}
