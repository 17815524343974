import { toFrontendCharacter } from "@/pages/app/home/frontendCharacter";
import { RenderableScenario } from "@/pages/api/trpc/scenarios/scenarioProcedures";
import { moderateCharacter } from "@/backend/services/characters/characters";
import { FrontendScenario } from "../../../../backend/frontendScenario";

export const frontendScenarioColumns = {
  id: true,
  characters: true,
  name: true,
  backdropImage: true,
  backdropImageAltText: true,
  backdropImageThumbnail: true,
  callToAction: true,
  description: true,
  type: true,
  continuationOptions: true,
  wordCount: true,
  isAdult: true,
}
export function toFrontendScenario(scenario: RenderableScenario) {
  return {
    id: scenario.scenario.id,
    characters: scenario.characters.map((character) =>
      toFrontendCharacter(character),
    ),
    name: scenario.scenario.title,
    backdropImage: scenario.scenario.previewImage,
    backdropImageAltText: scenario.scenario.background,
    backdropImageThumbnail: scenario.scenario.previewImage + "?tr=w-500",
    callToAction: scenario.scenario.tagline,
    description: scenario.scenario.description,
    type: scenario.scenario.scenarioType,
    continuationOptions: scenario.scenario.continuationOptions,
    wordCount: scenario.viewCount,
    isAdult: !scenario.scenario.passesModeration,
  } satisfies FrontendScenario;
}
