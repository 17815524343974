import { FrontendCharacter } from "../../../../app/home/frontendCharacter";
import { Box, Popover, Tooltip } from "@radix-ui/themes";
import Image from "next/image";
import { imageKitLoader } from "@/utils/imageKitLoader";
import { useRouterUtils } from "@/app/routerUtils";
import { useRef, useState } from "react";
export function CharacterAvatar({
  character,
  index,
  loadEarly,
  size = "180px",
  enableShortcut = false
}: {
  index: any;
  character: FrontendCharacter;
  loadEarly: boolean;
  size?: string;
  enableShortcut?: boolean;
}) {
  const {
    openCharacter
  } = useRouterUtils();
  const aprilFirstCeos = [{
    id: 195908,
    src: "https://ik.imagekit.io/x2dirkim6/images/site/april_1st.png?updatedAt=1743495809141",
    alt: "Ridiculously jacked, shirtless Sam Altman flexing while holding an OpenAI flag, all mocked up as a Pokémon card. The card hilariously warns 'This content may violate our content policies' right under his bulging muscles. Complete with HP stats and everything you'd expect from a trading card, it's basically poking fun at AI censorship while making Altman look like he could bench press a server farm."
  }, {
    id: 195913,
    src: "https://ik.imagekit.io/x2dirkim6/images/site/april_1st_demis.png?updatedAt=1743499283738",
    alt: `Demis Hassabis as a Pokémon card with HP 120 and a purple eye symbol. He's depicted with an absurdly oversized brain replacing his hair, wearing blue glasses and reading "How to Do Product for DUMMIES." His power is "Big Brain" with the description "His brain is really big and smart." The skinny, shirtless caricature contrasts hilariously with his enormous cerebrum.`
  }, {
    id: 195911,
    src: "https://ik.imagekit.io/x2dirkim6/images/site/april_1st_satya_nadella.png?updatedAt=1743498994783",
    alt: `Buffed-up Satya Nadella flexing on a Pokémon card with HP 200 and a fire symbol. The muscular Microsoft CEO has the Windows logo tattooed on one bicep and Azure logos on the other. His special move "Cloud Computing" states "This move is virtually unstoppable." The background shows clouds, creating a visual pun on his cloud computing power.`
  }, {
    id: 195914,
    src: "https://ik.imagekit.io/x2dirkim6/images/site/april_1st_jensen.png?updatedAt=1743498653302",
    alt: `Jacked Jensen Huang on a Pokémon card (HP 210) proudly displaying an NVIDIA graphics card. The elderly but ripped NVIDIA CEO has the special ability "Graphics Card" described as "Impressive GPU that everyone wants to pull." The card perfectly captures the GPU shortage joke with his absurdly muscular physique.`
  }, {
    id: 195910,
    src: "https://ik.imagekit.io/x2dirkim6/images/site/april_1st_clem.png?updatedAt=1743499889502",
    alt: `Clement Delangue as a beefy runner on a Pokémon card with HP 200, sprinting alongside a herd of llamas. His special move "Herd Advantage" reads "This attack does 30 more damage for each Llama in your party" - a clever nod to Hugging Face (which he co-founded) and its mascot, while he's portrayed as ridiculously muscular in a black tank top.`
  }];
  const audioRef = useRef<HTMLAudioElement>(null);
  const playSound = () => {
    const audio = new Audio("/audio/boom.m4a");
    audio.play();
    if (audioRef.current) {
      audioRef.current.volume = 0.25;
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
  };
  const [open, setOpen] = useState(false);
  return <Box style={{
    position: "absolute",
    bottom: "43%",
    left: `calc(.5 * ${size} * ${index})`,
    zIndex: 8 - index,
    width: size,
    height: size
  }} className={`object-cover object-top `} data-sentry-element="Box" data-sentry-component="CharacterAvatar" data-sentry-source-file="characterAvatar.tsx">
      <Box className={"relative w-full h-full group p-8"} data-sentry-element="Box" data-sentry-source-file="characterAvatar.tsx">
        {aprilFirstCeos.some(ceo => ceo.id === character.id) && <Tooltip open={open} onOpenChange={open => {
        if (open) {
          playSound();
        }
        setOpen(open);
      }} content={<Image src={aprilFirstCeos.find(ceo => ceo.id === character.id)!.src} alt={aprilFirstCeos.find(ceo => ceo.id === character.id)!.alt} width={521} height={782} />}>
            <div style={{
          position: "absolute",
          inset: 20,
          zIndex: 999999999999999,
          pointerEvents: "all"
        }}>
              <audio ref={audioRef} src="/audio/boom.m4a" preload="auto" />
            </div>
          </Tooltip>}

        <Image alt={character.backstory} loader={imageKitLoader} onClick={e => {
        if (enableShortcut) {
          openCharacter(character.id);
          e.stopPropagation();
          e.preventDefault();
        }
      }} loading={loadEarly ? "eager" : "lazy"} fill style={{
        paddingRight: `calc(.3 * ${size})`,
        paddingTop: "1.5rem",
        borderRadius: "999999px",
        overflow: "hidden",
        WebkitFilter: "drop-shadow(0px 5px 2px #000000)",
        filter: "drop-shadow(0px 5px 2px #000000)",
        objectFit: "cover",
        objectPosition: "top"
      }} className={enableShortcut ? "hover:scale-[103%] transition-transform duration-300 ease-in-out" : undefined} sizes="200px, (max-width: 768px) 300px, (max-width: 1200px) 300px" src={character.avatarUrlHd} data-sentry-element="Image" data-sentry-source-file="characterAvatar.tsx" />
      </Box>
    </Box>;
}