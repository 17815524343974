import { Button, Dialog, Strong } from "@radix-ui/themes";
import { useState } from "react";
import { useUser } from "@/hooks/useUser";
import { AuthDialogSignedOutContent } from "@/pages/app/auth/authDialogSignedOutContent";
import { AuthDialogSignedInContent } from "@/pages/app/auth/authDialogSignedInContent";
import { useUserProfile } from "@/utils/auth/useUserProfile";
export function AuthDialog() {
  const [isOpen, setIsOpen] = useState(false);
  const user = useUser();
  return <Dialog.Root open={isOpen} onOpenChange={setIsOpen} data-sentry-element="unknown" data-sentry-component="AuthDialog" data-sentry-source-file="authDialog.tsx">
      <Dialog.Trigger data-sentry-element="unknown" data-sentry-source-file="authDialog.tsx">
        <Button radius={"full"} variant={user ? "outline" : "solid"} size={"2"} color={"lime"} onClick={event => setIsOpen(old => !old)} data-sentry-element="Button" data-sentry-source-file="authDialog.tsx">
          {user ? "Account" : <Strong>Sign In</Strong>}
        </Button>
      </Dialog.Trigger>

      {user ? <AuthDialogSignedInContent /> : <AuthDialogSignedOutContent onClose={() => setIsOpen(false)} />}
    </Dialog.Root>;
}